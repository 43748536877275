import { GetStaticProps } from "next"
import Error from "next/error"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"

import { includeDefaultNamespaces } from "../i18n"

export default function NotFound(): JSX.Element {
  // Opinionated: do not record an exception in Sentry for 404
  return <Error statusCode={404} />
}

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, includeDefaultNamespaces([]))),
  },
})
